import colors from "config/colors";
import Constants from "expo-constants";
import { useCalendars } from "expo-localization";
import { isEqual, sortBy } from "lodash";
import { DateTime } from "luxon";
import randomWords from "random-words";
import { useCallback } from "react";
import {
  ItemPreparationStatuses,
  OrderInternalStatuses,
  OrderKinds,
} from "store/Orders/enums";
import type { DropoffLocation, InternalNote } from "store/Orders/types";
import { AppVariants } from "../../../constants";

export * from "./constants";

export const getColorForItemStatus = (status) => {
  switch (status) {
    case ItemPreparationStatuses.NOT_BEING_PREPARED:
      return colors.ui.statuses.active;
    case ItemPreparationStatuses.BEING_PREPARED:
      return colors.ui.statuses.cooking;
    case ItemPreparationStatuses.PREPARED:
      return colors.ui.statuses.prepared;
  }
};

export const getColorForOrderStatus = (status) => {
  switch (status) {
    case OrderInternalStatuses.NOT_BEING_PREPARED:
      return colors.ui.statuses.active;
    case OrderInternalStatuses.BEING_PREPARED:
      return colors.ui.statuses.cooking;
    case OrderInternalStatuses.PREPARED:
      return colors.ui.statuses.prepared;
    case OrderInternalStatuses.SCHEDULED:
      return colors.ui.statuses.scheduled;
    case OrderInternalStatuses.CANCELLED:
      return colors.ui.statuses.cancelled;
  }
};

export const getIconNameForOrderKind = (orderKind) => {
  switch (orderKind) {
    case OrderKinds.DELIVERY: {
      return "car";
    }
    case OrderKinds.CUSTOMER_PICKUP: {
      return "human-greeting";
    }
    case OrderKinds.WALK_IN: {
      return "table-furniture";
    }
    case OrderKinds.DRIVE_THRU: {
      return "car-convertible";
    }
    case OrderKinds.THIRD_PARTY_DELIVERY: {
      return "scooter";
    }
  }
};

export const getLabelKeyForOrderKind = (kind) => {
  switch (kind) {
    case OrderKinds.DELIVERY: {
      return "chef.item.orderKinds.delivery";
    }
    case OrderKinds.CUSTOMER_PICKUP: {
      return "chef.item.orderKinds.pickup";
    }
    case OrderKinds.WALK_IN: {
      return "chef.item.orderKinds.walkIn";
    }
    case OrderKinds.DRIVE_THRU: {
      return "chef.item.orderKinds.driveThru";
    }
    case OrderKinds.THIRD_PARTY_DELIVERY: {
      return "chef.item.orderKinds.thirdPartyDelivery";
    }
  }
};

export const getErrorID = (count = 4) => {
  return randomWords({ exactly: count, join: "-" });
};

export const formatDateTime = (
  date: Date,
  format: Intl.DateTimeFormatOptions = DateTime.DATETIME_MED,
  hour12?: boolean
) => {
  return DateTime.fromJSDate(date).toLocaleString({
    hour12,
    ...format,
  });
};

export const APP_VARIANT: string = Constants.expoConfig.extra.appVariant;

export const IS_LEGACY_BUILD: boolean = [
  AppVariants.CAPTAIN_LEGACY,
  AppVariants.CAPTAIN_LEGACY_PREVIEW,
].includes(APP_VARIANT);

export const IS_ANY_PREVIEW_BUILD: boolean = [
  AppVariants.CAPTAIN_PREVIEW,
  AppVariants.CAPTAIN_LEGACY_PREVIEW,
].includes(APP_VARIANT);

export const useFormatDateTime = () => {
  const calendars = useCalendars();

  return useCallback(
    (
      date: Date | string,
      format: Intl.DateTimeFormatOptions = DateTime.DATETIME_MED
    ) => {
      return formatDateTime(
        new Date(date),
        format,
        !(calendars && calendars[0]?.uses24hourClock)
      );
    },
    [calendars && calendars[0]?.uses24hourClock]
  );
};

export const isPermutation = (arr1: any[], arr2: any[]) =>
  isEqual(sortBy(arr1), sortBy(arr2));

export const getRouteName = (
  { index = 0, routes }: { index: number; routes: any[] },
  depth = 0
) => {
  const getInnerRoutes = () => {
    const routeState = routes[index]?.state;

    if (!routeState) return routes[index].name;

    return { index: routeState.index, routes: routeState.routes };
  };

  if (!routes) {
    return null;
  }

  if (depth <= 0) {
    return routes[index].name;
  }

  return getRouteName(getInnerRoutes(), depth - 1);
};

export const getAddressLine = (location: DropoffLocation) => {
  return [location.line_1, location.postcode].filter(Boolean).join(", ");
};

export const replaceLinebreaks = (str: string, replacement) => {
  return str.replace(/(\r\n|\n|\r)/gm, replacement);
};

/**
 * Creates a single string out of internal notes.
 *
 * Replacing linebreaks is needed because if a LONG note starts with "{short_text} {linebreak}" and we limit
 * displayed text to a single line and {short_text} isn't enough to fill it - then text overflow won't trigger.
 *
 * Because we want both to replace linebreaks (with ";") and to separate individual notes (with ";") we may end up
 * with ";;" which doesn't look nice. Replacing ";;" might remove parts of original text.
 * That's why an arbitrary separator is used instead.
 *
 * @param internalNotes
 */
export const mergeInternalNotes = (internalNotes?: InternalNote[]) => {
  const SEPARATOR = "@#$_SEPARATOR_@#$";
  const SEPARATOR_FILLER = "; ";

  const merged = internalNotes.map((item) => item.note).join(SEPARATOR);
  const withoutLinebreaks = replaceLinebreaks(merged, SEPARATOR);

  const withoutDuplicateSeparators = withoutLinebreaks.replaceAll(
    `${SEPARATOR}${SEPARATOR}`,
    SEPARATOR_FILLER
  );
  const withoutSolitarySeparators = withoutDuplicateSeparators.replaceAll(
    SEPARATOR,
    SEPARATOR_FILLER
  );

  return withoutSolitarySeparators;
};
