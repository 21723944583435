import * as Linking from "expo-linking";

export const RouteNames = {
  Auth: {
    _: "auth",
    Login: "sign-in",
    ForgotPassword: "forgot-password",
  },
  SelectStore: {
    _: "select-store",
  },
  Device: {
    _: "device",
    SelectScreen: "select-screen",
    Manager: "manager",
  },
  ReadyAccount: {
    _: "ReadyAccount",
  },
  BundleMigration: {
    _: "BundleMigration",
  },
  Home: {
    _: "home",
    Main: {
      _: "main",
      ItemDetails: "item-details",
      Drawer: {
        _: "list",
        Tabs: {
          _: "tabs",
          Overview: {
            _: "overview",
            OrderDetails: "order-details",
          },
          Chef: {
            _: "chef",
          },
        },
      },
    },
    Settings: {
      _: "settings",
      General: "general",
      Interface: "interface",
      Sounds: "sounds",
      Scheduled: "scheduled",
      Categories: "categories",
      OrderKinds: "order-kinds",
      Timing: "timing",
    },
  },
};

const screens = {
  [RouteNames.Auth._]: {
    path: "",
    screens: {
      [RouteNames.Auth.Login]: RouteNames.Auth.Login,
      [RouteNames.Auth.ForgotPassword]: RouteNames.Auth.ForgotPassword,
    },
  },
  [RouteNames.SelectStore._]: {
    path: RouteNames.SelectStore._,
  },
  [RouteNames.Home._]: {
    path: RouteNames.Home._,
    screens: {
      [RouteNames.Device.SelectScreen]: {
        path: RouteNames.Device.SelectScreen,
      },
      [RouteNames.Device.Manager]: {
        path: "verify-device",
      },
      [RouteNames.Device._]: {
        path: "",
        screens: {
          [RouteNames.Home.Main._]: {
            path: "",
            screens: {
              [RouteNames.Home.Main
                .ItemDetails]: `${RouteNames.Home.Main.ItemDetails}/:uuid?`,
              [RouteNames.Home.Main.Drawer._]: {
                path: "",
                screens: {
                  [RouteNames.Home.Main.Drawer.Tabs._]: {
                    path: "",
                    screens: {
                      [RouteNames.Home.Main.Drawer.Tabs.Overview._]: {
                        path: RouteNames.Home.Main.Drawer.Tabs.Overview._,
                        screens: {
                          [RouteNames.Home.Main.Drawer.Tabs.Overview
                            .OrderDetails]: `${RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails}/:uuid?`,
                        },
                      },
                      [RouteNames.Home.Main.Drawer.Tabs.Chef._]: {
                        path: RouteNames.Home.Main.Drawer.Tabs.Chef._,
                      },
                    },
                  },
                },
              },
            },
          },
          [RouteNames.Home.Settings._]: {
            path: RouteNames.Home.Settings._,
            screens: {
              [RouteNames.Home.Settings.General]:
                RouteNames.Home.Settings.General,
              [RouteNames.Home.Settings.Timing]:
                RouteNames.Home.Settings.Timing,
              [RouteNames.Home.Settings.Interface]:
                RouteNames.Home.Settings.Interface,
              [RouteNames.Home.Settings.Sounds]:
                RouteNames.Home.Settings.Sounds,
              [RouteNames.Home.Settings.Scheduled]:
                RouteNames.Home.Settings.Scheduled,
              [RouteNames.Home.Settings.Categories]:
                RouteNames.Home.Settings.Categories,
              [RouteNames.Home.Settings.OrderKinds]:
                RouteNames.Home.Settings.OrderKinds,
            },
          },
        },
      },
    },
  },
};

const linkingConfig = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens,
  },
};

export default linkingConfig;
