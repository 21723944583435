import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "react-native";
import { useSelector } from "react-redux";
import type { RootState } from "store/types";
import { UserSelectors } from "store/User";
import { IS_LEGACY_BUILD } from "util/helpers";
import {
  migrateToNonLegacyApp,
  MigrationData,
  selectStateMigrationData,
} from "util/helpers/bundleMigration";

export function useMigrateToNonLegacyApp() {
  const isAuthenticated = useSelector(UserSelectors.selectIsAuthenticated);
  const data: MigrationData | null = useSelector((state: RootState) =>
    isAuthenticated ? selectStateMigrationData(state) : null
  );
  const { t } = useTranslation();

  return useCallback(async () => {
    if (!IS_LEGACY_BUILD) {
      return;
    }

    try {
      await migrateToNonLegacyApp(data);
    } catch (e) {
      console.log(e);
      Alert.alert(
        t("common.error"),
        t("bundleMigration.errorInitiatingMigration")
      );
    }
  }, [data, t]);
}
