import { useSelector } from "react-redux";
import useInterval from "react-use/lib/useInterval";
import {
  BundleMigrationActions,
  BundleMigrationSelectors,
} from "store/BundleMigration";
import { useAppDispatch } from "store/hooks";
import { useOnReturnFromBackground } from "util/hooks/useOnReturnFromBackground";

export function useResetMigrationDismissal() {
  const dispatch = useAppDispatch();
  const migrationDismissed = useSelector(
    BundleMigrationSelectors.selectIsDismissed
  );

  useInterval(
    () => {
      dispatch(BundleMigrationActions.setDismissed(false));
    },
    migrationDismissed ? 1000 * 60 * 30 : null
  );

  useOnReturnFromBackground(() =>
    dispatch(BundleMigrationActions.setDismissed(false))
  );
}
