import { createReducer } from "@reduxjs/toolkit";
import { isEmpty, sortBy } from "lodash";
import { AuthThunks } from "store/Auth";
import * as AuthActions from "store/Auth/actions";
import { BundleMigrationActions } from "store/BundleMigration";
import { UserActions } from "store/User/actions";
import type { Account, UserState } from "store/User/types";

const initialState: UserState = {
  token: null,
  email: null,
  selectedAccountUUID: null,
  accounts: {},
  _token: null,
  _email: null,
  _accounts: {},
  selectedAccountReady: false,
};

export const UserReducer = createReducer(initialState, (builder) => {
  builder.addCase(UserActions.fetchAccountSuccess, (state, action) => {
    state.accounts[action.payload.uuid] = action.payload;
  });

  builder.addCase(UserActions.setSelectedAccountReady, (state, action) => {
    state.selectedAccountReady = action.payload;
  });

  builder.addCase(UserActions.updateAccount, (state, action) => {
    const { uuid, changes } = action.payload;

    if (uuid === state.selectedAccountUUID) {
      for (const key in changes) {
        if (Object.prototype.hasOwnProperty.call(changes, key)) {
          state.accounts[uuid][key] = changes[key];
        }
      }
    }
  });

  builder.addCase(UserActions.selectAccount, (state, action) => {
    if (!action.payload) {
      state.selectedAccountUUID = null;
    } else {
      const { uuid } = action.payload as Account;

      state.selectedAccountUUID = uuid;
      if (!isEmpty({ ...state._accounts })) state.accounts = state._accounts;
      if (state._token) state.token = state._token;
      if (state._email) state.email = state._email;
    }
  });

  builder.addCase(AuthThunks.login.fulfilled, (state, action) => {
    const { response } = action.payload;
    const { token, email, accounts } = response;

    state._token = token;
    state._email = email;
    state._accounts = Object.fromEntries(
      sortBy(accounts, (account) => account.name.toLowerCase()).map(
        (account: Account) => [account.uuid, account]
      )
    );
  });

  builder.addCase(AuthActions.signOut.type, () => initialState);

  builder.addCase(BundleMigrationActions.setCompleted, (state, action) => {
    const { user } = action.payload.migratedState;
    return { ...initialState, ...user };
  });
});
