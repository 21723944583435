const EASConfig = require("./eas.json");
// App Variant === whitelabel | app copy with a different bundle id
// Value is being passed to the app via `.extra.APP_VARIANT` property.
//
// Used during BUILD:
// - Evaluating config & base assets.
//
// Used during RUNTIME:
// - Selecting assets (at the moment - only in-app logos).
// - Selecting color scheme.
// - Shared with backend as a header (BE uses it when forming some responses).
// - Shared with Analytics.
//
// TODO - runtime-selected assets can be replaced build time (don't need to bundle extra assets).
const AppVariants = {
  CAPTAIN: EASConfig.build.production.env.APP_VARIANT,
  CAPTAIN_PREVIEW: EASConfig.build.captain_preview.env.APP_VARIANT,
  CAPTAIN_LEGACY: EASConfig.build.captain_legacy.env.APP_VARIANT,
  CAPTAIN_LEGACY_PREVIEW:
    EASConfig.build.captain_legacy_preview.env.APP_VARIANT,
};

module.exports = {
  AppVariants,
};
