import { RouteProp, useRoute } from "@react-navigation/native";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "react-native";
import { BundleMigrationActions } from "store/BundleMigration";
import { useAppDispatch } from "store/hooks";
import { MigrationData, parseData } from "util/helpers/bundleMigration";

export function useDigestMigration() {
  const dispatch = useAppDispatch();
  const route = useRoute<RouteProp<{ params: { dataMigration?: string } }>>();
  const { t } = useTranslation();

  useEffect(() => {
    if (route?.params?.dataMigration) {
      try {
        const parsedData: MigrationData = parseData(route.params.dataMigration);
        dispatch(
          BundleMigrationActions.setCompleted({ migratedState: parsedData })
        );
      } catch (e) {
        console.log(e);
        Alert.alert(
          t("common.error"),
          t("bundleMigration.errorMigrationFailed")
        );
      }
    }
  }, [dispatch, route?.params?.dataMigration, t]);
}
