import * as Linking from "expo-linking";
import { IS_ANY_PREVIEW_BUILD } from "util/helpers";
import { encodeData } from "./data";
import type { MigrationData } from "./redux";

const captainScheme = "captain-kitchen";
const captainPreviewScheme = "captain-kitchen-preview";

const targetScheme: string = IS_ANY_PREVIEW_BUILD
  ? captainPreviewScheme
  : captainScheme;

export async function hasNonLegacyAppInstalled(): Promise<boolean> {
  const URL = Linking.createURL("", {
    scheme: targetScheme,
  });
  return await Linking.canOpenURL(URL);
}

export async function migrateToNonLegacyApp(data?: MigrationData) {
  const URL = Linking.createURL("", {
    scheme: targetScheme,
    queryParams: {
      dataMigration: data ? encodeData(data) : null,
    },
  });

  await Linking.openURL(URL);
}
