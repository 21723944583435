import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "store/types";

const selectState = (state: RootState) => state;

function getParts(state: RootState) {
  const {
    categories,
    devices: { registeredUUID, seenScreenSelection },
    user: { token, selectedAccountUUID, accounts, email },
    app: { selectedTabBar, environment, isSidebarMinimized, locale },
  } = state;

  return {
    categories,
    devices: {
      registeredUUID,
      seenScreenSelection,
    },
    user: {
      token,
      selectedAccountUUID,
      accounts,
      email,
    },
    app: {
      selectedTabBar,
      environment,
      isSidebarMinimized,
      locale,
    },
  };
}

export type MigrationData = ReturnType<typeof getParts>;

export const selectStateMigrationData = createSelector(selectState, getParts);
