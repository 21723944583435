import { MigrationData } from "./redux";

export function encodeData(data: MigrationData): string {
  return JSON.stringify(data);
}

export function parseData(data: string): MigrationData {
  const decodedData = decodeURIComponent(data);
  return JSON.parse(decodedData);
}
