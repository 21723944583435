import { useCallback, useEffect, useRef } from "react";
import { AppState } from "react-native";

export function useOnReturnFromBackground(callback: () => void) {
  const appStateRef = useRef<string>(AppState.currentState);
  const callbackRef = useRef<typeof callback>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const onChange = useCallback((nextAppState: string) => {
    if (
      appStateRef.current?.match(/^(inactive|background)$/) &&
      nextAppState === "active"
    ) {
      if (callbackRef.current) {
        callbackRef.current();
      }
    }
    appStateRef.current = nextAppState;
  }, []);

  useEffect(() => {
    const listener = AppState.addEventListener("change", onChange);

    return () => listener.remove();
  }, [onChange]);
}
