import { Text } from "components";
import { StyleSheet } from "react-native";

type ContentProps = {
  titleKey: string;
  descriptionKey: string;
};

export function Content({ titleKey, descriptionKey }: ContentProps) {
  return (
    <>
      <Text id={titleKey} font="bold" style={styles.title} />
      <Text id={descriptionKey} style={styles.description} />
    </>
  );
}

const styles = StyleSheet.create({
  description: {
    fontSize: 18,
    marginBottom: 12,
    textAlign: "center",
  },
  title: {
    fontSize: 20,
    marginVertical: 12,
    textAlign: "center",
  },
});
