import { createSelector } from "@reduxjs/toolkit";
import type { BundleMigrationState } from "store/BundleMigration/slice";
import type { RootState } from "store/types";

const select = (state: RootState): BundleMigrationState =>
  state.bundleMigration;

const selectIsDismissed = createSelector(select, (slice) => slice.isDismissed);

export const BundleMigrationSelectors = {
  selectIsDismissed,
};
