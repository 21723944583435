import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Button, Text } from "components";
import { AuthBackground } from "modules/Auth/components/AuthBackground";
import { Content } from "navigation/components/EnvironmentWatcher/Content";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import useMount from "react-use/lib/useMount";
import { BundleMigrationActions } from "store/BundleMigration";
import { useAppDispatch } from "store/hooks";
import { UserSelectors } from "store/User";
import { useCountdown, useMigrateToNonLegacyApp } from "util/hooks";

export function MigrationScreen() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useSelector(UserSelectors.selectIsAuthenticated);

  const { t } = useTranslation();

  const onDismiss = () => {
    dispatch(BundleMigrationActions.setDismissed(true));
  };

  const [secondsRemaining, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: 60,
      intervalMs: 1000,
    });

  const migrate = useMigrateToNonLegacyApp();

  useMount(() => {
    resetCountdown();
    startCountdown();
  });

  useEffect(() => {
    if (secondsRemaining <= 0) {
      stopCountdown();
      resetCountdown();
      migrate();
    }
  }, [secondsRemaining, migrate, resetCountdown, stopCountdown]);

  return (
    <AuthBackground>
      <View style={styles.container}>
        <Content
          titleKey="bundleMigration.title"
          descriptionKey="bundleMigration.description"
        />
        <TouchableOpacity onPress={onDismiss} style={styles.buttonClose}>
          <MaterialCommunityIcons name={"close"} size={30} />
        </TouchableOpacity>
        <Text size={18} align={"center"} style={styles.text}>
          {t("bundleMigration.description2", { value: secondsRemaining })}
        </Text>
        {isAuthenticated && (
          <Text
            size={18}
            id={"bundleMigration.description3"}
            align={"center"}
          />
        )}
        <Button
          titleId={"bundleMigration.buttonOK"}
          onPress={migrate}
          style={styles.ok}
        />
      </View>
    </AuthBackground>
  );
}

const styles = StyleSheet.create({
  buttonClose: {
    opacity: 0.2,
    position: "absolute",
    right: 10,
    top: 10,
  },
  container: {
    alignSelf: "center",
    backgroundColor: "white",
    borderRadius: 8,
    maxWidth: 800,
    padding: 16,
  },
  ok: {
    alignSelf: "center",
    height: 50,
    marginTop: 24,
    width: 200,
  },
  text: {
    marginBottom: 12,
  },
});
