import { useNavigation } from "@react-navigation/native";
import Color from "color";
import { Text } from "components";
import colors from "config/colors";
import { AcceptButton } from "modules/Notifications/components";
import {
  getClarificationStringForNotificationMeta,
  getLabelKeyForNotificationType,
  useGetTimeLabel,
} from "modules/Notifications/helpers";
import { RouteNames } from "navigation/linkingConfig";
import React, { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { TouchableHighlight } from "react-native-gesture-handler";
import { useSelector } from "react-redux";
import {
  ChangeNotificationTypesList,
  NotificationTypes,
} from "store/Notifications/enums";
import type { AppNotification } from "store/Notifications/types";
import { OrderSelectors } from "store/Orders";
import { useNavigateToOrderDetails } from "util/hooks";
import { useNotificationState } from "./NotificationStateContext";
import { PriorityIcon } from "./PriorityIcon";

export type NotificationProps = {
  notification: AppNotification;
  onPress?: () => void;
  onPressAccept?: () => void;
  titleSize?: number;
  descriptionSize?: number;
  right?: ReactNode;
};

export function Notification({
  notification,
  onPress,
  onPressAccept,
  right,
  titleSize = 14,
  descriptionSize = 14,
}: NotificationProps) {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const {
    uuid,
    order_uuid: orderUUID,
    item_uuid: itemUUID,
    notification_type: notificationType,
    priority,
    meta,
    created_at: createdAt,
    accepted_at: acceptedAt,
  } = notification;

  const { onOpen: onOpenPopover } = useNotificationState();

  const order = useSelector((state) =>
    OrderSelectors.selectById(state, orderUUID)
  );
  const item = useSelector((state) =>
    itemUUID ? OrderSelectors.selectItemById(state, itemUUID) : null
  );

  const navigateToOrderDetails = useNavigateToOrderDetails(orderUUID);

  const onNavigate = useMemo(() => {
    if (notificationType === NotificationTypes.COMPANY) {
      return onOpenPopover;
    }

    return () => {
      if (itemUUID) {
        navigation.navigate(RouteNames.Home.Main.ItemDetails, {
          uuid: itemUUID,
        });
      } else {
        navigateToOrderDetails();
      }
    };
  }, [
    notificationType,
    onOpenPopover,
    itemUUID,
    navigation,
    navigateToOrderDetails,
  ]);

  const getTimeLabel = useGetTimeLabel();

  const backgroundColor = !notification.read_at
    ? Color("#3bc3ed").fade(0.8).hexa()
    : "white";

  const shouldDisplayChangeClarification =
    ChangeNotificationTypesList.includes(notificationType);

  const clarificationLabel =
    shouldDisplayChangeClarification &&
    getClarificationStringForNotificationMeta(notification.meta);

  return (
    <TouchableHighlight
      style={{
        backgroundColor,
        paddingHorizontal: 12,
      }}
      underlayColor={
        !notification.read_at
          ? Color("#3bc3ed").fade(0.5).hexa()
          : Color("#efefef").fade(0.5).hexa()
      }
      accessibilityRole={"link"}
      onPress={() => {
        onNavigate();
        onPress && onPress();
      }}
    >
      <View
        style={{
          paddingVertical: 10,
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <View style={{ marginRight: 10, minWidth: 70, gap: 4 }}>
          <View style={{ flexDirection: "row", gap: 4, alignItems: "center" }}>
            <PriorityIcon priority={priority} size={32} />
            <Text font={"bold"} color={colors.text.dark} size={descriptionSize}>
              {order && `#${order.description}`}
            </Text>
          </View>
          <Text color={"#9b9b9b"} size={12}>
            {getTimeLabel(createdAt)}
          </Text>
        </View>
        <View
          style={{
            marginRight: "auto",
            flexWrap: "wrap",
            flexGrow: 1,
            flexShrink: 1,
            gap: 4,
          }}
        >
          <Text
            size={titleSize}
            color={colors.text.dark}
            font="medium"
            style={{
              maxWidth: "100%",
            }}
          >
            {notification.subject ??
              t(getLabelKeyForNotificationType(notificationType))}
          </Text>
          {!!notification.message && (
            <Text
              size={titleSize}
              color={colors.text.dark}
              numberOfLines={3}
              style={{
                maxWidth: "100%",
              }}
            >
              {notification.message}
            </Text>
          )}
          {clarificationLabel && (
            <Text
              size={titleSize}
              color={colors.lightGray}
              style={{
                maxWidth: "100%",
              }}
            >
              ({clarificationLabel})
            </Text>
          )}
          {(item || notificationType === NotificationTypes.ITEM_REMOVED) && (
            <Text
              color={"#9b9b9b"}
              size={12}
              style={{
                maxWidth: "100%",
              }}
            >
              [
              {notification.notification_type === NotificationTypes.ITEM_REMOVED
                ? notification.meta.name
                : item.name}
              ]
            </Text>
          )}
        </View>
        {right ? (
          right
        ) : onPressAccept ? (
          <AcceptButton
            notificationUUID={uuid}
            acceptedAt={acceptedAt}
            onPress={onPressAccept}
          />
        ) : null}
      </View>
    </TouchableHighlight>
  );
}
