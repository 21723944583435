import { useCallback, useState } from "react";
import useEffectOnce from "react-use/lib/useEffectOnce";
import useInterval from "react-use/lib/useInterval";
import { IS_LEGACY_BUILD } from "util/helpers";
import { hasNonLegacyAppInstalled } from "util/helpers/bundleMigration";
import { useOnReturnFromBackground } from "./useOnReturnFromBackground";

export function useHasNonLegacyAppInstalled() {
  const [hasNonLegacyInstalled, setHasNonLegacyInstalled] = useState<boolean>(
    !IS_LEGACY_BUILD
  );

  const check = useCallback(async () => {
    if (!IS_LEGACY_BUILD) {
      return;
    }
    try {
      const result = await hasNonLegacyAppInstalled();
      setHasNonLegacyInstalled(result);
    } catch (e) {
      console.log(e);
      setHasNonLegacyInstalled(false);
    }
  }, []);

  useEffectOnce(() => {
    check();
  });

  useOnReturnFromBackground(check);

  // Non-legacy app can be installed in background mode via MDM - `useOnReturnFromBackground` won't trigger then.
  // Let's recheck once a minute or so just in case.
  useInterval(check, 1000 * 60);

  return hasNonLegacyInstalled;
}
