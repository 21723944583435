import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MigrationData } from "util/helpers/bundleMigration";

export type BundleMigrationState = {
  isDismissed: boolean;
};

const initialState: BundleMigrationState = {
  isDismissed: false,
};

type MigrationActionPayload = {
  migratedState: MigrationData;
};

const slice = createSlice({
  name: "bundleMigration",
  initialState,
  reducers: {
    setDismissed: (state, action) => {
      state.isDismissed = action.payload;
    },
    setCompleted: (state, action: PayloadAction<MigrationActionPayload>) => {},
  },
});

export const {
  actions: BundleMigrationActions,
  reducer: BundleMigrationReducer,
} = slice;
